import { useCallback, useEffect, useMemo, useState } from "react";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { useRecoilState } from "recoil";
import { IDateRangeSerialized, dateRangeAtom } from "../../../../state/search-state";

import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "../../../../fe-ui/hooks/use-translation";
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, useMediaQuery, useTheme } from "@mui/material";
import { fDate } from "../../../../fe-ui/utils/formatTime";
import { ITimeSlicesDto } from "../../../../api/fetchSingleUnitGroupCalendar";
import { singleOfferDateRangeAtom } from "../../../../state/single-offer-state";
import { Iconify } from "../../../../fe-ui/atoms/Iconify";
import { useSnackbar } from "notistack";
import dataTestIds from "../../../../utils/data-test-ids";

type IDateRange = [Dayjs | null, Dayjs | null];

function dateRangeSerializable(v): IDateRangeSerialized {
  const [a, b] = v;
  return [a ? dayjs(a).format("YYYY-MM-DD") : null, b ? dayjs(b).format("YYYY-MM-DD") : null];
}

type DatePickerModalProps = {
  timeSlices?: ITimeSlicesDto | null;
  defaultValue?: IDateRange;
  onClose: () => void;
  onAccept: (a: IDateRange) => void;
  minStayDays?: number;
};

const DatepickerStatic = ({
  calendarsCount = 1,
  onChange,
  onError,
  defaultValue,
  timeSlices,
  minStayDays = 0,
}: {
  onChange?: (v: IDateRange) => void;
  onError?: (v: boolean) => void;
  calendarsCount: 1 | 2;
  defaultValue?: IDateRange;
  timeSlices?: ITimeSlicesDto | null;
  minStayDays?: number;
}) => {
  const t = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState<IDateRange>(() => {
    return defaultValue || [null, null];
  });

  useEffect(() => {
    onChange?.(value);
  }, [value]);

  useEffect(() => {
    const [from, to] = value;
    if (from && to) {
      if (minStayDays) {
        if (to.diff(from, "day") < minStayDays) {
          setValue([to, null]);
          enqueueSnackbar(t("booking_min_stay_days_error", { days: minStayDays }), { variant: "error" });
          return onError?.(true);
        }
      }

      let current = from.clone();
      while (current.isBefore(to)) {
        current = current.add(1, "day");

        // range contains disabled dates
        if (timeSlices && timeSlices[current.format("YYYY-MM-DD")]?.available === false) {
          setValue([to, null]);
          return onError?.(true);
        }
      }
    }

    return onError?.(false);
  }, [value, timeSlices, onError, minStayDays]);

  return (
    <div data-testid={dataTestIds.SEARCH.DATE_PICKER.CALENDAR}>
      <StaticDateRangePicker
        sx={{
          "& .Mui-disabled": {
            textDecoration: "line-through",
          },
        }}
        disablePast
        disableDragEditing={false}
        defaultValue={value}
        reduceAnimations
        value={value}
        onChange={(v) => {
          setValue(v);
        }}
        shouldDisableDate={(day, position) => {
          const [arrivalDate] = value;
          const disabledByTimeSlice = timeSlices?.[day.format("YYYY-MM-DD")]?.available === false;

          if (arrivalDate && position === "end") {
            return day.isSame(value[0]) || disabledByTimeSlice;
          }

          return disabledByTimeSlice;
        }}
        localeText={{
          toolbarTitle: t("datepicker_select_daterange_title"),
          cancelButtonLabel: t("cta_cancel"),
          okButtonLabel: t("cta_apply"),
          start: t("cta_start"),
          end: t("cta_end"),
        }}
        slots={{
          actionBar: () => null,
        }}
        calendars={calendarsCount}
      />
    </div>
  );
};

const DatepickerModal = ({ timeSlices, onClose, onAccept, defaultValue, minStayDays }: DatePickerModalProps) => {
  const t = useTranslation();
  const [value, setValue] = useState<IDateRange>(() => {
    return defaultValue || [null, null];
  });
  const [isOpen] = useState(true);

  const [invalidRangeError, setInvalidRangeError] = useState(false);

  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const calendarsCount = isSM ? 1 : 2;
  const dialogPaddingTop = isSM ? 0 : 2;

  return (
    <>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={onClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: isSM ? "auto" : 680,
            },
          },
        }}
      >
        <DialogContent sx={{ pt: dialogPaddingTop, typography: "body2" }}>
          <DatepickerStatic
            onError={setInvalidRangeError}
            timeSlices={timeSlices}
            defaultValue={defaultValue}
            onChange={setValue}
            calendarsCount={calendarsCount}
            minStayDays={minStayDays}
          />
          {/* {invalidRangeError && (
            <Alert sx={{ mt: 2 }} severity="error">
              {t("errors_invalid_range_disabled_dates")}
            </Alert>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button
            data-ym={"SEARCH_DATEPICKER_CANCEL"}
            data-testid="datepicker-cancel-button"
            variant="text"
            color="inherit"
            onClick={onClose}
          >
            {t("cta_cancel")}
          </Button>
          <Button
            data-ym={"SEARCH_DATEPICKER_APPLY"}
            disabled={invalidRangeError}
            data-testid="datepicker-submit-button"
            onClick={() => onAccept(value)}
            color="primary"
            variant="contained"
          >
            {t("cta_apply")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

type Props = {
  defaultValue?: IDateRange;
  dateFormat?: "default" | "full";
  context: "single" | "search";
  noIcon?: boolean;
} & {
  fullWidth?: boolean;
  readOnly?: boolean;
  mode?: "button";
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
  noDatesLabel?: string;
} & {
  timeSlices?: ITimeSlicesDto | null;
  minStayDays?: number;
};

export const FormSearchDatePickerV2 = ({
  timeSlices,
  size = "large",
  readOnly,
  // mode = "button",
  fullWidth,
  dateFormat = "default",
  variant = "text",
  noDatesLabel,
  context,
  noIcon,
  minStayDays,
}: Props) => {
  const [dateRangeSaved, setDateRange] = useRecoilState(
    context === "search" ? dateRangeAtom : singleOfferDateRangeAtom
  );
  const computedDefaultValue = useMemo<IDateRange>(() => {
    const [a, b] = dateRangeSaved;
    return [a ? dayjs(a) : null, b ? dayjs(b) : null];
  }, [dateRangeSaved]);
  const t = useTranslation();
  const [dateArrival, dateDepature] = dateRangeSaved;

  const format = dateFormat === "full" ? "DD MMMM" : "DD MMM";

  const label =
    dateArrival && dateDepature
      ? `${fDate(dateArrival, format)} – ${fDate(dateDepature, format)}`
      : noDatesLabel || t("datepicker_select_daterange_title");

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleAccept = useCallback((newDateRange: IDateRange) => {
    handleClose();
    //
    setDateRange(dateRangeSerializable(newDateRange));
  }, []);

  return (
    <>
      {open && (
        <DatepickerModal
          minStayDays={minStayDays}
          timeSlices={timeSlices}
          defaultValue={computedDefaultValue}
          onAccept={handleAccept}
          onClose={handleClose}
        />
      )}
      <Button
        disabled={readOnly}
        sx={{
          color: readOnly ? "#212B36 !important" : "inherit",
          whiteSpace: "nowrap",
        }}
        fullWidth={fullWidth}
        color="inherit"
        variant={variant}
        size={size}
        onClick={handleOpen}
        data-testid={dataTestIds.SEARCH.DATE_PICKER.BUTTON}
        data-ym={"SEARCH_DATEPICKER"}
      >
        {!noIcon && <Iconify icon="lucide:calendar" sx={{ mr: 1, color: "#15AF97" }} />}
        {label}
      </Button>
    </>
  );
};

// https://stackoverflow.com/questions/72069782/how-to-disable-certain-days-and-dates-in-mui-react-datepicker-based-on-a-json-ar
