import { getRecoil } from "recoil-nexus";
// import { userSelectedLanguageIdAtom } from "../../state/user-state";

export function getLocale() {
  if (typeof window !== "undefined") {
    if (window["__LOCALE__"]) {
      return window["__LOCALE__"];
    }
  }
}

export function getCurrency() {
  return "AZN";
}
