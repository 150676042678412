import { useMediaQuery, Box, Typography, Divider } from "@mui/material";
import { Stack } from "@mui/system";
import { useTheme } from "@mui/system";

import FormSearchCompact from "./FormSearchCompact";
import { Props } from "./Props";
import { FormSearchSubmitButton } from "./FormSearchSubmitButton";
import { useTranslation } from "../../../fe-ui/hooks/use-translation";
import { SearchGuestsInput } from "../../widgets/SearchGuestsInput/SearchGuestsInput";
import FormSearchHotelPicker from "./FormSearchHotelPicker";
import { FormSearchDatePickerV2 } from "./widgets/FormSearchDatePickerV2";

export const FormSearch = (props: Props) => {
  const { hotelIdContext, type, navigateToSearchPage } = props;
  const isCompact = type === "compact";
  const t = useTranslation();

  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("md"));

  if (isCompact) {
    return <FormSearchCompact {...props} />;
  }

  return (
    <>
      <Stack
        justifyContent={"space-between"}
        spacing={!isSM ? 2 : 0}
        p={1.5}
        alignItems={"center"}
        direction={{ xs: "column", md: "row" }}
      >
        {!hotelIdContext && (
          <>
            <Stack sx={{ width: "100%" }} direction={"row"} alignItems={"center"} justifyContent="space-between">
              {isSM && (
                <Typography sx={{ color: "text.secondary" }} variant="body2">
                  {t("Booking_hotel_name")}
                </Typography>
              )}
              <Box sx={{ width: isSM ? undefined : 200 }}>
                <FormSearchHotelPicker hotels={props.hotels} />
              </Box>
            </Stack>
            {isSM && <Divider sx={{ borderStyle: "dashed" }} />}
            {!isSM && <Box sx={{ margin: 0, color: "#BFBAB9" }}>{"|"}</Box>}
          </>
        )}

        <Stack
          sx={{ width: "100%", flexGrow: 1 }}
          direction={"row"}
          alignItems={"center"}
          justifyContent="space-between"
        >
          {isSM && (
            <Typography sx={{ color: "text.secondary" }} variant="body2">
              {t("Booking_dates")}
            </Typography>
          )}
          <FormSearchDatePickerV2 fullWidth={!isSM} context="search" dateFormat="default" />
        </Stack>

        {isSM && <Divider sx={{ borderStyle: "dashed" }} />}
        {!isSM && <Box sx={{ margin: 0, color: "#BFBAB9" }}>{"|"}</Box>}

        <Stack
          sx={{ width: "100%", flexGrow: 1 }}
          direction={"row"}
          alignItems={"center"}
          justifyContent="space-between"
        >
          {isSM && (
            <Typography sx={{ color: "text.secondary" }} variant="body2">
              {t("Booking_guests")}
            </Typography>
          )}
          <SearchGuestsInput fullWidth={!isSM} context="search" />
        </Stack>

        <Box>
          <FormSearchSubmitButton navigateToSearchPage={navigateToSearchPage} />
        </Box>
      </Stack>
    </>
  );
};

export default FormSearch;
