import { format, getTime, formatDistanceToNow } from "date-fns";
import dayjs from "dayjs";
import { getLocale } from "./getLocale";

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

function toDate(d) {
  return dayjs(d);
}

function formatRU(d) {
  return toDate(d)
    .locale("ru")
    .format("DD.MM.YYYY");
}

function formatAZ(d) {
  return toDate(d)
    .locale("az")
    .format("DD.MM.YYYY");
}

function formatDefault(d) {
  return toDate(d).format("MM-DD-YYYY");
}

export function fDate(date: InputValue, forceCustomFormat?: string, options?: { hideYearWhenCurrentYear?: boolean }) {
  const locale = getLocale();
  if (forceCustomFormat) {
    const parsedDate = toDate(date);
    if (options?.hideYearWhenCurrentYear && parsedDate.year() === dayjs().year()) {
      return parsedDate
        .locale(locale)
        .format("DD MMMM");
    }
    return parsedDate
      .locale(locale)
      .format(forceCustomFormat);
  }
  switch (locale) {
    case "ru":
      return formatRU(date);
    case "az":
      return formatAZ(date);
  }
  return formatDefault(date);
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm) : "";
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : "";
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : "";
}
