import { Alert, Button, IconButton, Popover, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { usePopover } from "../../../fe-ui/hooks/use-popover";
import { useRecoilState, useRecoilValue } from "recoil";
import { adultsCounterAtom, childrenCounterAtom, totalGuestsCounterSelector } from "../../../state/booking-state";
import { InputCounter } from "../../../fe-ui/atoms/InputCounter";
import { useTranslation } from "../../../fe-ui/hooks/use-translation";
import { Iconify } from "../../components";

type Props = {
  readOnly?: boolean;
  fullWidth?: boolean;
  variant?: "icon" | "inline";
  size?: "small" | "medium" | "large";
  maxGuests?: number;
  context: "search" | "single";
  noIcon?: boolean;
};

const SearchGuestsInputBody = ({
  // popover,
  inline = false,
  onChange,
  maxGuests,
  readOnly,
}: {
  popover?: any;
  inline?: boolean;
  maxGuests?: number;
  onChange?: ({ adultsCount, childrenCount }) => void;
  readOnly?: boolean;
}) => {
  const [adultsCountSaved, saveAdultsCount] = useRecoilState(adultsCounterAtom);
  const [childrenCountSaved, saveChildrenCount] = useRecoilState(childrenCounterAtom);
  const [adultsCount, setAdultsCount] = useState(() => adultsCountSaved);
  const [childrenCount, setChildrenCount] = useState(() => childrenCountSaved);
  const t = useTranslation();
  const totalGuests = adultsCount + childrenCount;
  const isInvalid = maxGuests && totalGuests > maxGuests;

  useEffect(() => {
    if (inline) {
      saveAdultsCount(adultsCount);
      saveChildrenCount(childrenCount);
    } else {
      onChange?.({ adultsCount, childrenCount });
    }
  }, [inline, adultsCount, childrenCount, saveAdultsCount, saveChildrenCount, onChange]);

  return (
    <>
      <Stack direction={"column"} spacing={2} sx={{ p: 2 }}>
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="subtitle1">{t("vzroslye")}</Typography>
          <InputCounter
            name="adults"
            disabledDecrease={readOnly}
            disabledIncrease={readOnly}
            min={1}
            max={10}
            onChange={setAdultsCount}
            value={adultsCount}
          />
        </Stack>

        <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="subtitle1" sx={{ lineHeight: 1 }}>
            {t("deti")}
            {/* <br />
            <Typography variant="caption" sx={{ color: "text.secondary", lineHeight: 1 }}>
              {t("tut-kakoi-to-tekst-pro-detei-i-ikh-vozrast-i-td")}
            </Typography> */}
          </Typography>

          <InputCounter
            name="children"
            disabledDecrease={readOnly}
            disabledIncrease={readOnly}
            min={0}
            max={10}
            onChange={setChildrenCount}
            value={childrenCount}
          />
        </Stack>

        {isInvalid && (
          <>
            <Alert severity="error">
              {t("errors-max-guests-exceded", {
                max: maxGuests,
              })}
            </Alert>
          </>
        )}
      </Stack>
    </>
  );
};

const SearchGuestsInputPopover = ({ popover, maxGuests, readOnly }) => {
  const t = useTranslation();
  const [adultsCountSaved, saveAdultsCount] = useRecoilState(adultsCounterAtom);
  const [childrenCountSaved, saveChildrenCount] = useRecoilState(childrenCounterAtom);
  const [adultsCount, setAdultsCount] = useState(() => adultsCountSaved);
  const [childrenCount, setChildrenCount] = useState(() => childrenCountSaved);

  const handleSave = () => {
    saveChildrenCount(childrenCount);
    saveAdultsCount(adultsCount);
    popover?.onClose();
  };

  const handleChange = ({ childrenCount, adultsCount }) => {
    setChildrenCount(childrenCount);
    setAdultsCount(adultsCount);
  };

  if (!popover.open) {
    return null;
  }

  return (
    <Popover
      keepMounted={false}
      id="guest-counter-popover"
      open={!readOnly && Boolean(popover.open)}
      onClose={popover.onClose}
      anchorEl={popover.open}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Stack direction={"column"} spacing={2} sx={{ p: 2, width: 300 }}>
        <Typography variant="h5">{t("kolichestvo-gostei")}</Typography>

        <SearchGuestsInputBody maxGuests={maxGuests} onChange={handleChange} popover={popover} />

        <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
          <Button
            data-ym={"GUEST_PICKER_CANCEL"}
            onClick={popover?.onClose}
            fullWidth
            variant="text"
            color="inherit"
            data-testid="guest-cancel-button"
          >
            {t("otmena")}
          </Button>
          <Button
            data-ym={"GUEST_PICKER_SAVE"}
            onClick={handleSave}
            fullWidth
            variant="contained"
            color="inherit"
            data-testid="guest-submit-button"
          >
            {t("primenit")}
          </Button>
        </Stack>
      </Stack>
    </Popover>
  );
};

export const SearchGuestsInput = (p: Props) => {
  const { fullWidth, variant, size = "large", readOnly, noIcon } = p;
  const t = useTranslation();
  const count = useRecoilValue(totalGuestsCounterSelector);
  const popover = usePopover();
  const isIcon = variant === "icon";
  const isInline = variant === "inline";
  const Component = (isIcon ? IconButton : Button) as typeof Button;

  if (isInline) {
    return <SearchGuestsInputBody readOnly={readOnly} maxGuests={p.maxGuests} inline popover={popover} />;
  }

  return (
    <>
      <Component
        data-ym={"GUESTS_PICKER"}
        disabled={readOnly}
        fullWidth={fullWidth}
        onClick={popover.onOpen}
        aria-owns={popover.open ? "guest-counter-popover" : undefined}
        aria-haspopup="true"
        size={size}
        color="inherit"
        data-testid="guest-amount-button"
        sx={{
          whiteSpace: "nowrap",
          color: readOnly ? "#212B36 !important" : "inherit",
        }}
      >
        {!isIcon && !noIcon && (
          <Iconify icon="material-symbols-light:person-outline" sx={{ mr: 1, color: "#15AF97" }} />
        )}
        {t("words_guest", {
          count,
        })}
      </Component>
      <SearchGuestsInputPopover readOnly={readOnly} maxGuests={p.maxGuests} popover={popover} />
    </>
  );
};
