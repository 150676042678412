import { useMemo } from "react";
import { useGetAllPropertyAttributes, useGetAllUnitAttributes } from "../api/hooks";

type IAttr = { id: number };
type IAttrs = IAttr[];

type Props = {
  unitAttributes?: IAttrs;
  hotelAttributes?: IAttrs;
};

export const useAttributeMapping = (p: Props) => {
  const { data: allHotelAttributes } = useGetAllPropertyAttributes();
  const { data: allUnitAttributes } = useGetAllUnitAttributes();
  const { hotelAttributes = [], unitAttributes = [] } = p;
  return useMemo(() => {
    if (!allUnitAttributes || !allHotelAttributes) {
      return {
        hotelAttributes: [],
        unitAttributes: [],
      };
    }

    return {
      hotelAttributes: allHotelAttributes
        .filter((attrGroup) => {
          return attrGroup.attributes.find((a) => hotelAttributes.find((x) => x.id === a.id));
        })
        .map((g) => {
          return {
            ...g,
            attributes: g.attributes.filter((attr) => hotelAttributes.find((x) => x.id === attr.id)),
          };
        }),
      unitAttributes: allUnitAttributes
        .filter((attrGroup) => {
          return attrGroup.attributes.find((a) => unitAttributes.find((x) => x.id === a.id));
        })
        .map((g) => {
          return {
            ...g,
            attributes: g.attributes.filter((attr) => unitAttributes.find((x) => x.id === attr.id)),
          };
        }),
    };
  }, [allHotelAttributes, allUnitAttributes, hotelAttributes, unitAttributes]);
};
