import { SearchOutlined } from "@mui/icons-material";
import { Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { dateRangeAtom, searchFullUrlSelector } from "../../../state/search-state";
import { useRouter } from "next/router";
import { useTranslation } from "../../../fe-ui/hooks/use-translation";

type Props = {
  isCompact?: boolean;
  navigateToSearchPage?: boolean;
  onClick?: () => void;
};

export const FormSearchSubmitButton = ({ isCompact, navigateToSearchPage, onClick }: Props) => {
  const { push } = useRouter();

  const [range] = useRecoilState(dateRangeAtom);
  const t = useTranslation();
  const theme = useTheme();
  // const selectedHotelId = useRecoilValue(selectedHotelAtom);
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const fullSearchLink = useRecoilValue(searchFullUrlSelector);

  const handleSubmit = useCallback(() => {
    if (navigateToSearchPage) {
      push(fullSearchLink);
    }
    onClick?.();
  }, [range, fullSearchLink]);

  const isReadyToSubmit = true; // JSON.stringify(range) !== JSON.stringify(draftDateRange);

  if (isCompact) {
    return (
      <Button
        disabled={!isReadyToSubmit}
        data-ym="search_submit_btn"
        data-testid="search-button"
        onClick={handleSubmit}
        variant={"contained"}
        color={"primary"}
      >
        <SearchOutlined />
      </Button>
    );
  }

  return (
    <Button
      data-ym="search_submit_btn"
      disabled={!isReadyToSubmit}
      onClick={handleSubmit}
      variant={"contained"}
      data-testid="search-button"
      startIcon={isXS ? "" : <SearchOutlined sx={{ width: "28px", height: "48px", marginLeft: "12px" }} />}
      sx={{ borderRadius: 4, p: isXS ? 2 : 0 }}
    >
      {isXS ? t("Index_buttonCtaFind") : ""}
    </Button>
  );
};
