import { useMediaQuery, Popover, Button, Box } from "@mui/material";
import { Stack } from "@mui/system";
import { fDate } from "../../../fe-ui/utils/formatTime";
import { useTheme } from "@mui/system";

import { useRecoilValue } from "recoil";
import { dateRangeAtom } from "../../../state/search-state";
import { Props } from "./Props";

import { FormSearchSubmitButton } from "./FormSearchSubmitButton";
import { useTranslation } from "../../../fe-ui/hooks/use-translation";
import FormSearchHotelPicker from "./FormSearchHotelPicker";
import { SearchGuestsInput } from "../../widgets/SearchGuestsInput/SearchGuestsInput";
import { usePopover } from "../../../fe-ui/hooks/use-popover";
import { FormSearchDatePickerV2 } from "./widgets/FormSearchDatePickerV2";

const FormSeatchCompactPopover = ({
  popover,
  ...props
}: {
  popover: ReturnType<typeof usePopover>;
} & Props) => {
  const { hotelIdContext } = props;

  return (
    <Popover
      keepMounted={false}
      id="form-search-compact-popover"
      open={Boolean(popover.open)}
      onClose={popover.onClose}
      anchorEl={popover.open}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Stack direction={"column"} spacing={3} sx={{ p: 2 }}>
        {!hotelIdContext && (
          <>
            <FormSearchHotelPicker hotels={props.hotels} />
          </>
        )}

        <FormSearchDatePickerV2 context="search" />
        <SearchGuestsInput context="search" size="medium" />
        <FormSearchSubmitButton navigateToSearchPage isCompact onClick={popover.onClose} />
      </Stack>
    </Popover>
  );
};

const FormSearchCompact = (props: Props) => {
  const { hotelIdContext } = props;
  const popover = usePopover();
  const t = useTranslation();

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const [from, to] = useRecoilValue(dateRangeAtom);

  if (isXS) {
    return (
      <>
        <Button
          // fullWidth={fullWidth}
          onClick={popover.onOpen}
          aria-owns={popover.open ? "form-search-compact-popover" : undefined}
          aria-haspopup="true"
          size={"small"}
          variant="contained"
          color="inherit"
          sx={{
            whiteSpace: "nowrap",
            px: 2,
          }}
        >
          {from && to ? (
            <>
              {fDate(from, "DD.MM")}–{fDate(to, "DD.MM")}
            </>
          ) : (
            <>{t("search_params")}</>
          )}
        </Button>

        {popover.open && <FormSeatchCompactPopover popover={popover} {...props} />}
      </>
    );
  }

  return (
    <Stack sx={{ flexGrow: 1 }} direction={"row"} alignContent={"space-between"} spacing={2} alignItems={"center"}>
      {!hotelIdContext && (
        <Box sx={{ width: 200 }}>
          <FormSearchHotelPicker hotels={props.hotels} size="small" />
        </Box>
      )}
      <FormSearchDatePickerV2 context="search" size="medium" dateFormat="default" />
      <SearchGuestsInput context="search" size="medium" />
      <FormSearchSubmitButton isCompact />
    </Stack>
  );
};

export default FormSearchCompact;
